<template>
    <iframe
      :src="slidoLink"
      ref="chat"
      width="100%"
      height="100%"
      frameBorder="0"
      class="align-top"
      title="Slido"
      allowfullscreen
    ></iframe>
</template>
<script>
export default {
  name: "Slido",
  props: ['currentUser', 'settings'],
  computed: {
    slidoLink() {
      let link = "";
      let chatId = this.settings.chat.id;
      let language = this.$root.$i18n.locale;
      let username = this.currentUser.username;
      let email = this.currentUser.email;
      let company = this.settings.client.name;

      link =
        "https://app.sli.do/event/" +
        chatId +
        "/live/questions?lang=" +
        language +
        "&user_name=" +
        username +
        "&user_email=" +
        email +
        "&user_company=" +
        company;

      return link;
    },
  },
};
</script>
