import AuthService from '@/services/AuthService';

const storedUser = localStorage.getItem('user');


const User = {
  namespaced: true,
  state: {
    status: storedUser ? { loggedIn: true } : { loggedIn: false },
    user: storedUser ? JSON.parse(storedUser) : null,
  },
  mutations: {
    loginSuccess(state: { status: { loggedIn: boolean; }; user: any; }, user: any) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state: { status: { loggedIn: boolean; }; user: any; }) {
      state.status.loggedIn = false
      state.user = null;
    },
    logout(state: { status: { loggedIn: boolean; }; user: any; }) {
      state.status.loggedIn = false;
      state.user = null;
    },
    registerSuccess(state: { status: { loggedIn: boolean; }; user: any; }, user: any) {
      state.status.loggedIn = true;
      state.user = user;
    },
    registerFailure(state: { status: { loggedIn: boolean; }; }) {
      state.status.loggedIn = false;
    }
  },
  actions: {
    login({commit}, data: any): Promise<any> {
      return AuthService.login(data.email, data.code).then(
        user => {
          commit('loginSuccess', user);
          return Promise.resolve(user);
        },
        error => {
          commit('loginFailure');
          const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          return Promise.reject(message);
        }
      );
    },
    signOut({commit}) {
      AuthService.logout();
      commit('logout');
    },
    register({commit}: any, data: { email: string; firstname: string; lastname: string; }): Promise<any> {
      return AuthService.register(data.email, data.firstname, data.lastname).then(
        user => {
          commit('registerSuccess', user),
          Promise.resolve(user);
        },
        error => {
          commit('registerFailure');
          const message =
            (error.response && error.response.data && error.response.data.message) ||
            error.message ||
            error.toString();
          return Promise.reject(message);
        }
      );
    }
  },
  getters: {
    isLoggedIn(state: { status: { loggedIn: any; }; }) {
      return state.status.loggedIn;
    }
  }
} as any

export default User;