<template>
<div>
  <Header/>
  <b-container class="login-card-container">
    <b-card class="auth-card">
      <form name="form" @submit.prevent="handleLogin">
        <div class="form-group">
          <label for="Email">{{ $t("email") }}</label>
          <input
            v-model="user.email"
            v-validate="'required'"
            type="text"
            class="form-control"
            name="Email"
          />
          <div v-if="errors.has('Email')" class="alert-danger">
            {{ errors.first("Email") }}
          </div>
        </div>
        <div class="form-group">
          <label for="code">{{ $t("code") }}</label>
          <input
            v-model="user.code"
            v-validate="'required|max:50'"
            type="password"
            class="form-control"
            name="code"
          />
          <div v-if="errors.has('code')" class="alert-danger">
            {{ errors.first("code") }}
          </div>
        </div>
        <div class="form-group">
          <button class="btn btn-primary btn-block" :disabled="loading">
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            {{ $t("login") }}
          </button>
        </div>
        <div class="form-group">
          <div v-if="message" class="alert alert-danger" role="alert">
            {{ message }}
          </div>
        </div>
      </form>
    </b-card>
  </b-container>
  <Footer/>
</div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: "CodeAuthentication",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      user: { email: "", code: ""},
      loading: false, 
      message: "",
    }
  },
  watch: {
    // Checks if user is logged in with basic Auth, push to home when true
    isLoggedIn: function() {
      console.log(this.isLoggedIn)
      if(this.isLoggedIn == true){
        this.$router.push("/")
      }
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['Auth/isLoggedIn']
    },
    settings() {
      return this.$store.state.Config.settings;
    },
  },
  mounted() {
    if (this.isLoggedIn == true) {
      this.$router.push("/")
    }
  },
  methods: {
    handleLogin() {
      this.message = "";

      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
         this.loading = false
         return
        }
        if (this.user.email && this.user.code) {
          this.$store.dispatch('Auth/login', this.user).then(
            (data) => {
              console.log(data)
              if (!data.succesful) this.message = data.error
              this.$router.push("/")
            },
            (error) => {
              this.loading = false
              this.message = error
            }
          );
        }
      })
    }
  }
}
</script>
<style lang="scss">
.auth-card {
  max-width: 20rem;
  margin: 0 auto;
  vertical-align: center;
}

.login-card-container {
    min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
    min-height: 75vh; /* These two lines are counted as one :-)       */

    display: flex;
    align-items: center;

  }
</style>