<template>
  <div>
    <b-button v-b-modal.modal-qr >
      <font-awesome-icon
              class="label-icon"
              icon="mobile"
      ></font-awesome-icon>
     {{ $t("QRbutton") }}
    </b-button>
    <b-modal id="modal-qr" body-class="center-content" role="dialog" centered hide-footer :title='$t("QRtitle")'>
      <qrcode class="mobile-qr" :options="{ width: 300 }" :value="QRLink"></qrcode>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: "ChatQR",
  props: ['currentUser', 'settings', 'chatType'],
  computed: {
    QRLink() {
      let link = "";
      let firstname = btoa(this.currentUser.firstname)
      let lastname = btoa(this.currentUser.lastname)
      let email = btoa(this.currentUser.email)
      
      const url = this.$router.resolve({
        name: 'mobileChat',
        query: { firstname, lastname, email } 
      })

      link = `${window.location.origin}${url.href}`
      
      console.log(link)

      return link;
    },
  },
};
</script>
<style>
.center-content {
  align-content: center;
  text-align: center;
  padding: 0px !important;
}
</style>