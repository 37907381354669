<template>
<div>
  <div id="app" class="color-primary background-image">
    <router-view />
  </div>
</div>
</template>

<script>

export default {
  name: "App",
  methods: {
    setTheme(colors, font, backgroundImage) {
      let style = document.documentElement.style;

      for (let key of Object.keys(colors)) {
        style.setProperty("--theme-color-" + key, colors[key]);
      }

      style.setProperty("--theme-font-family", font);
      style.setProperty("--theme-background-image", backgroundImage);
    },
  },
  data() {
    return {
      title: "Corda"
    }
  },
  metaInfo() { 
    return {
      title: this.settings.client.name
    }
  },
  computed: {
    settings() {
      return this.$store.state.Config.settings;
    },
  },
  async created() {
    await this.$store.dispatch("Config/getSettings");
    const favicon = document.getElementById("favicon")
    favicon.href = this.settings.client.favicon
    
    let colors = {
      primary: this.settings.styling.primary,
      secondary: this.settings.styling.secondary,
      text_color_header: this.settings.styling.text_color_header,
      text_color_title: this.settings.styling.text_color_title,
      text_color_body: this.settings.styling.text_color_body,
      warning: this.settings.styling.warning,
      success: this.settings.styling.success,
      danger: this.settings.styling.danger,
    };
    console.log(colors);

    let font = this.settings.styling.fontfamily;

    let backgroundImage = "url('')"
    
    if (this.settings.features.backgroundImage) {
      backgroundImage = "url(" + this.settings.client.background + ")";
      
    } else {
      backgroundImage = "url('')"
    }

    this.setTheme(colors, font, backgroundImage);
  }
};
</script>

<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.color-primary {
  background-color: $color-primary !important;
}

.color-secondary {
  background-color: $color-secondary !important;
}

.text-color-header {
  p, a, h1, h2, h3, h4, h5, span {
    color: $text-color-header;
  }
  color: $text-color-header;
}

.text-color-title {
  p, a, h1, h2, h3, h4, h5, span {
    color: $text-color-title;
  }
  color: $text-color-title;
}

.text-color-body {
  p, a, h1, h2, h3, h4, h5, span {
    color: $text-color-body;
  }
  color: $text-color-body;
}

html,
body {
  min-width: 0px !important;
  width: 100%;
  height: 100%;
  background-color: $color-primary;
}

#app {
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-image {
  background-image: $background-image;
  position: absolute;
  height: 100%;
  width: 100%;
  background-size: cover;
  overflow: auto;
}

body { 
  overflow: auto !important;
}

body > #zmmtg-root {
  display: none !important;
}

#zmmtg-root, .meeting-client, .meeting-client-inner {
  width: 100% !important;
  height: 100% !important;
  position: relative !important;
}

#wc-footer {
  bottom: auto !important;
  width: 100% !important;
}

#dialog-join {
  width: 100% !important;
}

#sv-active-video, .active-main, #sv-active-speaker-view, .main-layout {
  height: 100% !important;
  width: 100% !important;
}

.suspension-window {
  transform: translate(-444px, 10px) !important;
}
</style>
