<template>
    <div class="freshchat-chat-container">
    </div>
</template>
<script>
import Vue from "vue";
import VueFreshchat from "vue-freshchat";

Vue.use(VueFreshchat, { appToken: "db70f809-7ec3-41e3-985b-fc64f7491c0f" });
export default {
    
    el: "#main",
    data() {
        return {
            userExternalId: 1,
        }
    },
    mounted() {
        this.$freshchat.setExternalId(this.userExternalId)
        this.$freshchat.open()
    },
    watch: {
        userEmail() {
            this.$freshchat.setUserProperties({ email: this.currentUser.email })
        },
    },
    computed: {
    livestream() {
      return this.$store.state.Config.settings.livestream;
    },
    settings() {
      return this.$store.state.Config.settings;
    },
    currentUserBasicAuth() {
      return this.$store.state.Auth.user;
    },
    currentUserAuth0() {
      return this.$auth0.user;
    },
    authenticationType() {
      return this.settings.features.authentication;
    },
    isLoggedIn() {
      if (
        this.$auth0.isAuthenticated() ||
        this.$store.getters["Auth/isLoggedIn"]
      ) {
        return true;
      } else {
        return false;
      }
    },
    currentUser() {
      let currentUser = {
        email: "",
        username: "",
      };
      if (
        this.$store.getters["Auth/isLoggedIn"] &&
        this.authenticationType == "basic"
      ) {
        currentUser.username =
          this.currentUserBasicAuth.firstname +
          " " +
          this.currentUserBasicAuth.lastname;
        currentUser.email = this.currentUserBasicAuth.email;
      } else if (
        this.$auth0.isAuthenticated &&
        this.authenticationType == "auth0"
      ) {
        currentUser.username = this.currentUserAuth0.username;
        currentUser.email = this.currentUserAuth0.email;
      } else {
        currentUser.username = "";
        currentUser.email = "";
      }
      return currentUser;
    },
  },
}
</script>