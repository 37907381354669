<template>
  <iframe
    :src="`https://www.youtube.com/embed/${settings.livestream.id}`"
    id="youtube-iframe"
    frameborder="0"
    class="video-player"
    allow="autoplay; fullscreen;"
    allowfullscreen
    data-youtube-tracked="true"
  ></iframe>
</template>
<script>
export default {
  name: "youtube",
  props: ["settings", "chatActive"]
}
</script>