<template>
  <div class="homepage">
    <Header />
    <Popup />
    <div class="main">
      <ChatQR 
        class="qr-button push-right"
        :currentUser="currentUser"
        :settings="settings"
        :chatType="settings.chat.type"
      />
      <b-tabs class="home-tabs">
        <b-tab title="Livestream" active>
          
          <template #title>
            <font-awesome-icon
              class="label-icon"
              icon="video"
            ></font-awesome-icon>
            <span class="tab-text">{{ $t("livestream") }}</span>
          </template>
          <div class="livestream-row row">
            <div
              class="video-player-container col-sm-12 col-md-12"
              :class="{ 'col-lg-9': chatActive, 'col-lg-12': !chatActive }"
            >
              <Vimeo
                class="video"
                :settings="settings"
                :chatActive="chatActive"
                v-if="settings.livestream.type == 'vimeo'"
              />
              <Zoom
                class="video"
                :settings="settings"
                v-if="settings.livestream.type == 'zoom'"
              />
              <Youtube
                class="video"
                :settings="settings"
                :chatActive="chatActive"
                v-if="settings.livestream.type == 'youtube'"
              />
              <div class="video-info" :class="{ 'info-push': !chatActive }">
                <h1 class="video-title text-color-title">{{ $t("livestream_title") }}</h1>
                <p class="video-description text-color-body">
                  {{ $t("livestream_description") }}
                </p>
              </div>
            </div>
            <div class="stream-chat-container col-md-12 col-lg-3">
              <Slido
                :currentUser="currentUser"
                :settings="settings"
                class="chat"
                v-if="settings.chat.type == 'slido'"
              />
              <VimeoChat
                :currentUser="currentUser"
                :settings="settings"
                class="chat"
                v-if="settings.chat.type == 'vimeo'"
              />
              <FreshChat
                :currentUser="currentUser"
                :settings="settings"
                class="chat"
                v-if="settings.chat.type == 'freshchat'"
              />
            </div>
          </div>
        </b-tab>
        <b-tab
          v-if="settings.interactive_livestream.type !== ''"
          @click="StopAllAudio()"
          title="Speeddate"
        >
          <template #title>
            <font-awesome-icon
              class="label-icon"
              icon="comments"
            ></font-awesome-icon>
            <span class="tab-text">{{ $t("conversation") }}</span>
          </template>
          <Whereby
            v-if="settings.interactive_livestream.type == 'whereby'"
          ></Whereby>
        </b-tab>
        <b-tab v-if="settings.attachments.length !== 0" title="Bijlagen">
          <template #title>
            <font-awesome-icon
              class="label-icon"
              icon="paperclip"
            ></font-awesome-icon>
            <span class="tab-text">{{ $t("attachments") }}</span>
          </template>
          <Attachments></Attachments>
        </b-tab>
      </b-tabs>
    </div>
    <Footer />
  </div>
</template>

<script>
import Zoom from "@/components/video/Zoom.vue";
import Youtube from "@/components/video/Youtube.vue";
import Vimeo from "@/components/video/Vimeo.vue";
import VimeoChat from "@/components/chat/VimeoChat.vue";
import Whereby from "@/components/chat/Whereby.vue";
import FreshChat from "@/components/chat/FreshChat.vue";
import Slido from "@/components/chat/Slido.vue";
import Attachments from "@/components/Attachments.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Popup from "@/components/Popup.vue";
import ChatQR from "@/components/chat/ChatQR";

export default {
  name: "Home",
  components: {
    Header,
    Footer,
    Popup,
    Slido,
    Vimeo,
    VimeoChat,
    FreshChat,
    Zoom,
    Youtube,
    Whereby,
    Attachments,
    ChatQR
  },
  computed: {
    settings() {
      return this.$store.state.Config.settings;
    },
    chatActive() {
      return this.settings.chat.type !== "";
    },
    currentUserBasicAuth() {
      return this.$store.state.Auth.user;
    },
    currentUserAuth0() {
      return this.$auth0.user;
    },
    isLoggedIn() {
      if (
        this.$auth0.isAuthenticated() ||
        this.$store.getters["Auth/isLoggedIn"]
      ) {
        return true;
      } else {
        return false;
      }
    },
    authenticationType() {
      return this.settings.features.authentication;
    },
    currentUser() {
      let currentUser = {
        firstname: "",
        lastname: "",
        email: "",
        username: "",
      };
      if (
        this.$store.getters["Auth/isLoggedIn"] &&
        this.authenticationType == "basic"
      ) {
        currentUser.username =
          this.currentUserBasicAuth.firstname +
          " " +
          this.currentUserBasicAuth.lastname;
        currentUser.firstname = this.currentUserBasicAuth.firstname;
        currentUser.lastname = this.currentUserBasicAuth.lastname;
        currentUser.email = this.currentUserBasicAuth.email;
      } else if (
        this.$auth0.isAuthenticated &&
        this.authenticationType == "auth0"
      ) {
        currentUser.firstname = this.currentUserAuth0.firstname;
        currentUser.lastname = this.currentUserAuth0.lastname;
        currentUser.username = this.currentUserAuth0.username;
        currentUser.email = this.currentUserAuth0.email;
      } else {
        currentUser.firstname = "";
        currentUser.lastname = "";
        currentUser.username = "";
        currentUser.email = "";
      }
      console.log(currentUser);
      return currentUser;
    },
  },
  methods: {
    StopAllAudio() {
      var iframe = this.$el.querySelector("iframe");
      var video = this.$el.querySelector("video");
      if (iframe) {
        var iframeSrc = iframe.src;
        iframe.src = iframeSrc;
      }
      if (video) {
        video.pause();
      }
    },
  },
};
</script>

<style lang="scss">
.main {
  /* grid-area: content; */
  max-width: 1600px;
  margin: 0 auto;
  max-height: 600px;
  padding-top: 35px;
  padding-left: 60px;
  padding-right: 60px;
}

.homepage
{
  overflow: auto;
  height: 100%;
}

.livestream-row .row {
  width: 100%;
  height: 100%;
  margin-right: 0px;
}

.video-info {
  position: relative;
  margin: 0;
  float: left;
  top: 0;
  margin-top: -17%;
  margin-left: -15px;
}

.video-player-container {
  position: relative;
  height: 100%;
  padding-top: 56.25%;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 75%;
}

.chat {
  height: 75%;
  min-height: 536.364px;
  min-width: 265.455px;
}

.info-push {
  margin-top: -13%;
  margin-bottom: 50px;
}

.stream-chat-container {
  position: relative;
  width: 100%;
}

.push-right {
  float: right;
}
@media only screen and (max-width: 750px) {
  .nav {
    flex-wrap: nowrap;
  }
  .qr-button { 
    display: none;
  }
}
@media only screen and (max-width: 472px) {
  .nav-tabs {
    flex-wrap: nowrap;
  }
  .tab-text {
    display: none;
    float: right;  
  } 
}

@media only screen and (max-width: 1375px) {
  .stream-chat-container {
    height: 100%;
  }
}

@media only screen and (max-width: 999px) {
  .main {
    padding-top: 35px;
    padding-left: 35px;
    padding-right: 35px;
    margin: 0 0;
  }

  .video-player-container {
    width: 100%;
    padding-top: 75%;
  }

  .stream-chat-container {
    margin-bottom: 50px;
    min-height: 750px;
  }
}
@media only screen and (max-width: 750px) {
  .video-info {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 650px) {
  .video-info {
    margin-top: 100px;
  }
  .video-description {
    display: none;
  }
}

@media only screen and (max-width: 320px) {
  .video-info {
    display: none;
  }
}
</style>
