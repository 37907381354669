import axios from 'axios'

const Popup = {
  namespaced: true,
  state: {
    messages: [],
    message:
      {
        email: "",
        text: "",
        url: ""
      }
  },
  getters: {
    message: (state: { message: any }) => state.message,
    messages: (state: { messages: any }) => state.messages,
    messagesByEmail: (state: any) => (email: any) => {
      return state.messages.filter(message => message.email === email)
    },
  },
  mutations: {
    SET_MESSAGE(state: { message: any }, message: any) {
      state.message = message
    },
    SET_MESSAGES(state: { messages: any }, messages: any) {
      state.messages = messages
    }
  },
  actions: {
    async getMessageByEmail({ commit }: any, email: any) {
      const response = await axios.get(process.env.VUE_APP_API_URL + '/message.php?email=' + email, {
        headers: { "Access-Control-Allow-Origin": "*" },
        responseType: 'json',
      })
      commit('SET_MESSAGE', response.data)
    },
    async getMessages({ commit }: any) {
      const response = await axios.get(process.env.VUE_APP_API_URL + '/message.php', {
        headers: { "Access-Control-Allow-Origin": "*" },
        responseType: 'json',
      })
      commit('SET_MESSAGES', response.data)
    }
  },
}
export default Popup