import axios from "@/plugins/axios.plugin";

class AuthService {
  async login(email: string, code: string) {
    return axios
      .post("/register-code.php", {
        headers: { "Access-Control-Allow-Origin": "*" },
        email,
        code,
      })
      .then((response) => {
        if (response.data.succesful) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  async register(email: string, firstname: string, lastname: string) {
    return axios
      .post( process.env.VUE_APP_API_URL + "/register.php", {
        headers: { "Access-Control-Allow-Origin": "*" },
        email,
        firstname,
        lastname,
      })
      .then((response) => {
        console.log("response" + response);
        if (response.status == 200) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }
}

export default new AuthService();
