<template>
  <div class="mobile">
    <Header />
    <div class="stream-chat-container mobile-chat-container col-md-12 col-lg-12">
      <Slido
        :currentUser="currentUser"
        :settings="settings"
        class="chat"
        v-if="settings.chat.type == 'slido'"
      />
      <VimeoChat
        :currentUser="currentUser"
        :settings="settings"
        class="chat"
        v-if="settings.chat.type == 'vimeo'"
      />
      <FreshChat
        :currentUser="currentUser"
        :settings="settings"
        class="chat"
        v-if="settings.chat.type == 'freshchat'"
      />
    </div>
    <Footer />
  </div>
</template>
<script>
import FreshChat from "@/components/chat/FreshChat.vue";
import Slido from "@/components/chat/Slido.vue";
import VimeoChat from "@/components/chat/VimeoChat.vue";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "MobileChat",
  components: {
    Slido,
    FreshChat,
    VimeoChat,
    Header,
    Footer,
  },
  data() {
    return {
      user: { firstname: "", lastname: "", email: "" },
      successful: false,
      message: "",
    };
  },
  created() {
    if (
      !this.$route.query.firstname ||
      !this.$route.query.lastname ||
      !this.$route.query.email
    ) {
      this.$router.push({ name: "Home" });
      return;
    }

    this.user.firstname = atob(this.$route.query.firstname);
    this.user.lastname = atob(this.$route.query.lastname);
    this.user.email = atob(this.$route.query.email);

    this.handleRegister();
  },
  methods: {
    handleRegister() {
      this.message = "";
      this.submitted = true;
      console.log(this.user)
     
      this.$store.dispatch("Auth/register", this.user).then(
        (data) => {
          console.log(data);
          this.message = data.message;
          this.successful = true;
        },
        (error) => {
          this.message = error;
          this.successful = false;
        }
      );
    },
  },
  computed: {
    settings() {
      return this.$store.state.Config.settings;
    },
    currentUser() {
      let currentUser = {
        email: "",
        username: "",
      };
      currentUser.username = this.user.firstname + " " + this.user.lastname;
      currentUser.email = this.user.email;

      return currentUser;
    },
  },
};
</script>
<style scoped>
  .mobile-chat-container {
    margin-top: 20px;
  }

  .mobile {
    overflow: auto !important;
    height: 100%;
  }

  @media only screen and (max-width: 999px) {
    .stream-chat-container {
      min-height: 550px;
      margin-bottom: 20px;
      height: 100%;
    }
  }

</style>