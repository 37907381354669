<template>
    <footer>
        <div class="footer-container">
            <p>Powered by <a href="https://www.tribespot.be">Tribespot</a></p>
        </div>
        <cookie-law buttonLink="/cookie-privacy" theme="dark-lime"></cookie-law>
    </footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
  export default {
    components: { CookieLaw }
  }
</script>

<style lang="scss">
$color-primary: var(--theme-color-primary);
$color-secondary: var(--theme-color-secondary);
$color-warning: var(--theme-color-warning);
$color-success: var(--theme-color-success);
$color-danger: var(--theme-color-danger);

$font-family: var(--theme-font-family);

.footer-container {
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $color-secondary;
    position: fixed;
    display:flex;
    flex-wrap: wrap;
    flex-direction:column;
    align-items:center;
    color: #fff;
    p {
        font-size: 15px;
        margin: 1em;
    }
}

</style>