<template>
    <header class="main-header color-primary text-color-header">
    <nav class="menu">
      <!-- <a href="#" class="menu-toggle text-replace sprite">Menu</a> -->
      <router-link
        to="/"
        class="logo text-replace"
        :style="{ backgroundImage: 'url(' + this.settings.client.logo + ')' }"
        >Tribespot</router-link
      >
    </nav>
    <nav class="actions push">
      <div class="nav">
        <!-- Admin link -->
        <!-- <router-link v-if="$auth0.isAuthenticated || this.isLoggedIn" class="nav-item profile-nav" to="/admin">
          <img class="nav-img nav-icon" src="/static/admin.png"/><span class="menu-text">{{ $t('admin') }}</span>
        </router-link> -->

        <!-- Profile link -->
        <!-- <div v-if="($auth0.isAuthenticated || this.isLoggedIn) && this.loginRequired"> -->
        <!-- <a href="#" class="notifications sprite nav-icon" v-if="$auth0.isAuthenticated" :style="{ backgroundImage: 'url(' + this.getSettings.client.notification + ')' }"></a> -->

        <!-- Basic authentication -->
        <!-- <router-link class="nav-item profile-nav" v-if="settings.features.authentication == 'basic'" to="/profile-b">
            <img class="nav-img nav-icon" :src="this.getSettings.client.profilepicture"/><span class="menu-text">{{ $t('profile') }}</span>
          </router-link> -->

        <!-- Auth0 -->
        <!-- <router-link class="nav-item profile-nav" v-if="settings.features.authentication == 'auth0'" to="/profile-a">
            <img class="nav-img nav-icon" :src="this.getSettings.client.profilepicture"/><span class="menu-text">{{ $t('profile') }}</span>
          </router-link>
        </div> -->

        <!-- Login / Logout -->
        <!-- Auth0 -->
        <div
          v-if="
            settings.features.authentication == 'auth0' &&
              !$auth0.loading &&
              this.loginRequired
              && !settings.features.coming_soon
          "
        >
          <a class="nav-item" v-if="!$auth0.isAuthenticated" @click="login">
            <b-icon
              icon="box-arrow-right"
              class="nav-img nav-icon log-icon"
            />
            <span class="menu-text login-text">{{ $t("login") }}</span>
          </a>
          <a class="nav-item" v-if="$auth0.isAuthenticated" @click="logout">
            <b-icon
              icon="box-arrow-right"
              class="nav-img nav-icon log-icon"
            />
            <span class="menu-text login-text">{{ $t("logout") }}</span>
          </a>
        </div>
        <!-- Basic authentication -->
        <div
          v-if="
            settings.features.authentication !== 'auth0' && this.loginRequired && !settings.features.coming_soon
          "
        >
          <a class="nav-item" v-if="!this.isLoggedIn" @click="login">
            <b-icon
              icon="box-arrow-in-right"
              class="nav-img nav-icon log-icon"
            />
            <span class="menu-text login-text">{{ $t("login") }}</span>
          </a>
          <a class="nav-item" v-if="this.isLoggedIn" @click="logout">
            <b-icon
              icon="box-arrow-right"
              class="nav-img nav-icon log-icon"
            />
            <span class="menu-text login-text">{{ $t("logout") }}</span>
          </a>
        </div>

        <!-- Language -->
        <select
          class="selectpicker language-picker color-primary text-color-header"
          data-width="fit"
          v-model="$root.$i18n.locale"
          v-if="languages.length > 1"
        >
          <option v-for="entry in languages" :value="entry" :key="entry">
            {{ $t(`languages.${entry}`) }}
          </option>
        </select>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  data() {
    return {
      isActive: true,
    };
  },

  methods: {
    login() {
      if (this.settings.features.authentication == "basic") {
        this.$router.push("/authentication");
      } else if (this.settings.features.authentication == "code") {
        this.$router.push("/login");
      } else if (this.settings.features.authentication == "auth0") {
        this.$auth0.loginWithRedirect();
      }
    },
    logout() {
      if (this.settings.features.authentication == "basic") {
        this.$store.dispatch("Auth/signOut");
        this.$router.push("/authentication");
      } else if (this.settings.features.authentication == "code") {
        this.$store.dispatch("Auth/signOut");
        this.$router.push("/login");
      } else if (this.settings.features.authentication == "auth0") {
        if (this.settings.features.loginRequired) {
          this.$auth0.logout({
            returnTo: window.location.origin,
          });
        } else {
          this.$auth0.logout({
            returnTo: "/",
          });
        }
      }
    },
  },
  computed: {
    languages() {
      return this.getLanguages;
    },
    loginRequired() {
      return this.settings.features.loginRequired;
    },
    getLanguages() {
      return this.settings.languages;
    },
    settings() {
      return this.$store.state.Config.settings;
    },
    isLoggedIn() {
      return this.$store.getters["Auth/isLoggedIn"];
    },
  },
};
</script>

<style lang="scss">
.main-header {
  position: relative;
  z-index: 100;
  display: flex;
  width: 100%;
  padding: 0.6923em 2.3077em;
  min-height: 80px;

  > * {
    display: flex;
    align-items: center;
  }
}

// .menu-toggle {
//   display: inline-block;
//   width: 16px;
//   height: 16px;
//   margin: 0 10px;
//   background-position: -471px -437px;
// }

.menu {
  width: calc(200px - 2em);
  margin-right: 2em;
}

.menu-text {
  font-size: 19px;
  font-weight: bold;
  text-transform: uppercase;
}

.nav-item:hover {
  color: black !important;
}


.logo {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 100%;
  width: 100%;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.main-header .actions {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.nav-item {
  margin-left: auto;
  display: flex;
  margin-left: 20px;
  :hover {
    cursor: pointer;
  }
}

.nav-item:hover{
  opacity: 0.8;
}

.nav-icon {
  width: 25px;
  height: 25px;
  margin: 0 0.6154em;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.log-icon {
  margin-top: 3.8px;
  width: 20px;
  height: 20px;
}

.language-picker {
  font-size: 19px;
  margin-left: 10px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;

  :hover {
    cursor: pointer;
  }
}

@media only screen and (max-width: 472px) {
  .menu-text {display: none;}
  
}
</style>
