var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{staticClass:"main-header color-primary text-color-header"},[_c('nav',{staticClass:"menu"},[_c('router-link',{staticClass:"logo text-replace",style:({ backgroundImage: 'url(' + this.settings.client.logo + ')' }),attrs:{"to":"/"}},[_vm._v("Tribespot")])],1),_c('nav',{staticClass:"actions push"},[_c('div',{staticClass:"nav"},[(
          _vm.settings.features.authentication == 'auth0' &&
            !_vm.$auth0.loading &&
            this.loginRequired
            && !_vm.settings.features.coming_soon
        )?_c('div',[(!_vm.$auth0.isAuthenticated)?_c('a',{staticClass:"nav-item",on:{"click":_vm.login}},[_c('b-icon',{staticClass:"nav-img nav-icon log-icon",attrs:{"icon":"box-arrow-right"}}),_c('span',{staticClass:"menu-text login-text"},[_vm._v(_vm._s(_vm.$t("login")))])],1):_vm._e(),(_vm.$auth0.isAuthenticated)?_c('a',{staticClass:"nav-item",on:{"click":_vm.logout}},[_c('b-icon',{staticClass:"nav-img nav-icon log-icon",attrs:{"icon":"box-arrow-right"}}),_c('span',{staticClass:"menu-text login-text"},[_vm._v(_vm._s(_vm.$t("logout")))])],1):_vm._e()]):_vm._e(),(
          _vm.settings.features.authentication !== 'auth0' && this.loginRequired && !_vm.settings.features.coming_soon
        )?_c('div',[(!this.isLoggedIn)?_c('a',{staticClass:"nav-item",on:{"click":_vm.login}},[_c('b-icon',{staticClass:"nav-img nav-icon log-icon",attrs:{"icon":"box-arrow-in-right"}}),_c('span',{staticClass:"menu-text login-text"},[_vm._v(_vm._s(_vm.$t("login")))])],1):_vm._e(),(this.isLoggedIn)?_c('a',{staticClass:"nav-item",on:{"click":_vm.logout}},[_c('b-icon',{staticClass:"nav-img nav-icon log-icon",attrs:{"icon":"box-arrow-right"}}),_c('span',{staticClass:"menu-text login-text"},[_vm._v(_vm._s(_vm.$t("logout")))])],1):_vm._e()]):_vm._e(),(_vm.languages.length > 1)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.$root.$i18n.locale),expression:"$root.$i18n.locale"}],staticClass:"selectpicker language-picker color-primary text-color-header",attrs:{"data-width":"fit"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$root.$i18n, "locale", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.languages),function(entry){return _c('option',{key:entry,domProps:{"value":entry}},[_vm._v(" "+_vm._s(_vm.$t(("languages." + entry)))+" ")])}),0):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }