<template>
<div>
<Header />
<b-tabs>
  <b-tab title="Cookies">
<div class="cookie-doc">
    <p>Wij, To The Point Events, met maatschappelijke zetel te Kempische Steenweg 303 b21 3500 Hasselt, met het bedrijfsnummer 0477.191.005, maken gebruik van cookies om de surfervaring van bezoekers op onze website <router-link to="/">{{url}}</router-link> verbeteren.
        Alle gegevens die wij verkrijgen via onze cookies worden uitsluitend gebruikt om uw gebruik op onze website te begrijpen, met het oog op het verbeteren van de prestaties, functionaliteiten en de gebruiksvriendelijkheid van onze website.
    </p>
    <p>Door de informatie en de diensten op <router-link to="/">{{ url }}</router-link> te gebruiken, bevestigt u dat u onze cookie policy en de voorwaarden die wij hierin hebben opgenomen, hebt gelezen en begrijpt. Een cookie is in het algemeen een klein tekst- en cijferbestand dat wij tijdens een bezoek aan onze website, versturen en opslaan in jouw webbrowser, op jouw mobiel apparaat en/of op de harde schijf van jouw computer. Op die manier kunnen wij jouw voorkeuren bij het gebruik van onze website onthouden (bv. je taalkeuze, voorkeursortering, etc.), tenzij je jouw browserinstellingen hebt aangepast zodat die cookies zal weigeren. Cookies zorgen voor de functionering van websites maar kunnen ook informatie verzamelen voor commerciële doeleinden.</p>
    <h1>SOORTEN COOKIES</h1>
    <p>Cookies kunnen worden onderverdeeld naar gelang hun oorsprong, hun functie en hun levensduur:</p>
    <p>Opgedeeld naar oorsprong kan een onderscheid gemaakt worden tussen enerzijds cookies die door onze onderneming zelf beheerd worden en anderzijds cookies waarvan het beheer in handen is van andere ondernemingen, die dat beheer in onze opdracht en voor onze rekening waarnemen. Wij gebruiken geen cookies van derde partijen die aan deze derde partijen toestaan dat zij de bekomen gegevens voor eigen gebruik kunnen aanwenden.</p>
    <p>Opgedeeld naar hun functie, is er sprake van:</p>
    <ul class="elipsis-list">
        <li><b>Onmisbare of strikt noodzakelijke cookies</b>, die (zoals hun naam aangeeft) noodzakelijk zijn om gebruik te maken van een website, bepaalde door jou gevraagde functies te kunnen aanbieden en zorgen voor een veilige identiteitscontrole;</li>
        <li><b>Functionele cookies</b> die ervoor zorgen dat de website naar behoren functioneert. Zij houden informatie over uw keuzes en voorkeuren op onze website bij (bv. cookies voor het onthouden van log-in of registratie, jouw taalvoorkeur, producten in uw winkelwagen). Functionele cookies zijn logischerwijs first party cookies;</li>
        <li><b>Statistische en analytische cookies</b> die ons verder inzicht geven in de mate van gebruik van onze website, zodat wij onder meer op basis van deze data de werking van de website en de doelgerichtheid ervan kunnen optimaliseren. Zo laten deze ons toe om bezoekers en hun herkomst te tellen zodat we de prestatie van onze website kunnen meten en verbeteren. Ze helpen ons inzicht te krijgen in welke pagina’s het meest en minst populair zijn en hoe bezoekers zich door de gehele site bewegen (Google Analytics, Google Tag Manager, Hotjar).</li>
        <li><b>Cookies met sociale doeleinden</b> maken het de gebruiker mogelijk om de inhoud van de bezochte website via sociale media rechtstreeks te delen met anderen (Facebook, Twitter, Linkedin, Whatsapp).</li>
        <li><b>Cookies met targeting/tracking doeleinden</b> maken het mogelijk het surfgedrag van bezoekers te volgen en laten toe dat op basis van uw surfgedrag een profiel wordt opgebouwd zodat de vertoonde advertenties worden afgestemd op uw interesses (Facebook). Het is verplicht om toestemming te vragen en te krijgen voor dergelijke cookies.</li>
        <li><b>Cookies met commerciële doeleinden</b> houden bij hoeveel en welke advertenties aan een gebruiker werden getoond.Cookies kunnen ook worden opgedeeld naargelang hun opslagduur, waarbij een onderscheid kan gemaakt worden tussen:</li>
        <li><b>Permanente cookies:</b> Deze cookies blijven op het apparaat van de gebruiker aanwezig voor de duur bepaald in de cookie. Ze worden geactiveerd telkens de gebruiker de website bezoekt die deze cookie heeft geplaatst (bv. cookies geplaatst door sociale media zoals Facebook, Google Analytics, etc.). De meeste niet-functionele cookies zijn permanente cookies.</li>
        <li><b>Sessie cookies:</b> Deze cookies laten ons toe de handelingen van een gebruiker te vereenvoudigen en aan elkaar te linken tijdens een browsersessie. Een browsersessie begint wanneer een gebruiker het browserscherm opent en eindigt wanneer zij het browserscherm sluiten. Sessie cookies worden tijdelijk geplaatst. Zodra u de browser afsluit, worden alle sessie cookies verwijderd. De meeste functionele cookies zijn sessie cookies.</li>
    </ul>
    <h1>GEBRUIKTE COOKIES</h1>
    <p>U kan hieronder per gebruikte cookie het beheer, de functie en de opslagduur nagaan; Indien de cookies die wij gebruiken persoonsgegevens verzamelen en verwerken, gebeurt dit in overeenstemming met onze <router-link to="privacy">privacy policy.</router-link> Hierin vind u tevens de rechten die u heeft m.b.t. persoonsgegevens.</p>
    
    <h1>COMPLIANCE</h1>
    <p>We gebruiken Complianz voor het registreren van toestemming voor cookies. <a href="https://cookiedatabase.org/service/complianz">Lees meer</a></p>
    <p><b>Type: functioneel</b></p>
    <b-table striped hover :items="compliance_items"></b-table>
    <p>Voor meer informatie, lees de <a href="https://complianz.io/privacy-statement/">Complianz Privacy Policy.</a></p>
    
    <h1>WPML</h1>
    <p>We gebruiken WPML voor taalbeheer. <a href="https://cookiedatabase.org/service/wpml">Lees meer</a></p>
    <p><b>Type: functioneel</b></p>
    <b-table striped hover :items="wpml_items"></b-table>
    <p>Deze gegevens worden niet gedeeld met derden.</p>

    <h1>GOOGLE ANALYTICS</h1>
    <p>We gebruiken Google Analytics voor websitestatistieken. <a href="https://cookiedatabase.org/service/google-analytics">Lees meer</a></p>
    <p><b>Type: statistieken (anoniem zonder ip)</b></p>
    <b-table striped hover :items="googleAnal_items"></b-table>
    <p>Voor meer informatie, lees de <a href="https://policies.google.com/privacy">Google Analytics Privacy Policy.</a></p>

    <h1>GOOGLE FONTS</h1>
    <p>We gebruiken Google Fonts voor het weergeven van weblettertypen. <a href="https://cookiedatabase.org/service/google-fonts">Lees meer</a></p>
    <p><b>Type: third-party (marketing/tracking)</b></p>
    <b-table striped hover :items="googleFont_items"></b-table>
    <p>Voor meer informatie, lees de <a href="https://policies.google.com/privacy">Google Fonts Privacy Policy.</a></p>

    <h1>VIMEO</h1>
    <p>We gebruiken Vimeo voor het tonen van video’s. <a href="https://cookiedatabase.org/service/vimeo">Lees meer</a></p>
    <p><b>Type: statistieken</b></p>
    <b-table striped hover :items="vimeo_items"></b-table>
    <p>Voor meer informatie, lees de <a href="https://vimeo.com/privacy">Vimeo Privacy Policy.</a></p>

    <h1>RUMBLETALK</h1>
    <p>We gebruiken RumbleTalk om geregistreerde gebruikers in groep te laten chatten, 1-op-1 of 1-op-1 video- en audiogesprekken te voeren. <a href="https://cookiedatabase.org/service/vimeo">Lees meer</a></p>
    <p><b>Type: third-party (marketing/tracking)</b></p>
    <b-table striped hover :items="rumble_items"></b-table>
    <p>Voor meer informatie, lees de <a href="https://rumbletalk.com/about_us/privacy_policy/">RumbleTalk Privacy Policy.</a></p>

    <h1>SLIDO</h1>
    <p>We gebruiken Slido om geregistreerde gebruikers de mogelijkheid te geven om vragen te stellen terwijl ze een livestream volgen of deel te nemen aan peilingen.</p>
    <p><b>Type: third-party (marketing/tracking)</b></p>
    <b-table striped hover :items="slido_items"></b-table>
    <p>Voor meer informatie, lees de <a href="https://www.sli.do/terms#privacy-policy">Slido Privacy Policy.</a></p>

    <h1>ZOOM</h1>
    <p>We gebruiken Zoom om geregistreerde gebruikers in staat te stellen met elkaar in contact te komen via videogroepgesprekken.</p>
    <p><b>Type: functioneel</b></p>
    <b-table striped hover :items="vimeo_items"></b-table>
    <p>Voor meer informatie, lees de <a href="https://zoom.us/privacy-and-legal">Zoom Privacy Policy.</a></p>

    <h1>COOKIES ACCEPTEREN</h1>
    <p>Bij uw eerste bezoek aan onze website word u gevraagd om onze verschillende soorten cookies te aanvaarden waarvoor uw toestemming nodig is. U blokkeert cookies door de browserinstelling te activeren die toelaat om het plaatsen van cookies te weigeren. Wanneer u echter uw browserinstellingen gebruikt om die cookies te blokkeren, zal u mogelijks geen toegang hebben tot (bepaalde delen van) onze website. Lees deze cookie policy aandachtig voor meer informatie hierover. Als u op een gegeven moment uw toestemming wil intrekken, kan u uw cookies ook steeds verwijderen d.m.v. uw browserinstellingen.</p>

    <h1>COOKIES VERWIJDEREN OF UITSCHAKELEN</h1>
    <p>Als u niet wilt dat websites cookies op uw computer plaatsen of u uw cookies wilt verwijderen, kan u uw cookie instellingen in uw webbrowser wijzigen. Deze instellingen zijn meestal terug te vinden in het menu ‘Opties’ of ‘Voorkeuren’ van uw webbrowser. U kan uw instellingen ook aanpassen zodat uw browser alle cookies of alleen de cookies van derde partijen weigert. Als u geen gebruik wilt maken van cookies, kunnen wij helaas niet garanderen dat onze website goed zal werken. Zo is het mogelijk dat er enkele functies van de website verloren gaan of dat u bepaalde pagina’s zelfs helemaal niet meer kunt zien. Daarnaast betekent het weigeren van cookies ook niet dat u geen advertenties meer te zien krijgt. De advertenties zijn dan alleen niet meer aangepast aan uw interesses en worden vaker herhaald. Hoe u uw instellingen kunt aanpassen, verschilt per browser. Om deze instellingen beter te begrijpen, kan u de ‘Help’ functie in uw webbrowser consulteren voor meer details.
Bijkomende nuttige informatie over cookies vind u ook op: 
<a href="http://www.allaboutcookies.org/">http://www.allaboutcookies.org/</a> en <a href="http://www.youronlinechoices.com/be-nl">http://www.youronlinechoices.com/be-nl</a>. </p>

    <h1>HEEFT U NOG VRAGEN?</h1>
    <p>Voor verdere vragen met betrekking tot deze cookie policy kan u contact met ons opnemen via <a href="info@tothepointevents.be">info@tothepointevents.be</a>.</p>

    <h1>WIJZIGINGEN</h1>
    <p>Wij kunnen deze cookie policy van tijd tot tijd wijzigen, vervolledigen of aanvullen zonder voorafgaandelijke kennisgeving. Wij nodigen u dan ook uit om steeds de laatste versie van deze policy te raadplegen op onze website. Uiteraard vragen we steeds uw toestemming voor het gebruik van bepaalde cookies.</p>
</div>
  </b-tab>
  <b-tab title="Privacy">
    <div class="privacy-doc">
         <h1>ONZE AANPAK VOOR HET BESCHERMEN VAN UW PRIVACY</h1>
         <p>Hartelijk dank om ons uw persoonsgegevens toe te vertrouwen. Wij, <b>To The Point Events</b>, met maatschappelijke zetel te Kempische Steenweg 303 b21 3500 Hasselt, met het bedrijfsnummer 0477.191.005, zijn verantwoordelijk voor de verwerking van uw persoonsgegevens met betrekking tot uw toekomstige deelname aan onze {{client}} (hierna, ‘Evenement’).
In dat opzicht treden wij op als verwerkingsverantwoordelijke van uw persoonsgegevens die de doeleinden en middelen van de verwerking van persoonsgegevens bepaalt in het kader van Verordening (EU) 2016/679 (“AVG”).
         </p>
         <p>Wij hechten veel belang aan uw privacy en we streven naar de bescherming ervan deze privacyverklaring na te leven. We zullen transparant optreden door u duidelijke informatie te geven over welke persoonsgegevens we verzamelen; wat we ermee doen en waarom; met wie we ze delen; hoe we persoonsgegevens beschermen en welke keuzes u heeft met betrekking tot het gebruik van uw persoonsgegevens door ons en derde partijen.</p>
        <p>We behouden het recht om deze privacyverklaring te allen tijde aan te passen indien nieuwe ontwikkelingen daartoe aanleiding geven. De meest recente privacyverklaring kan u altijd op de website <router-link to="/">{{url}}</router-link> vinden.</p>
        <h1>WELKE PERSOONSGEGEVENS VERWERKEN WE EN WAAROM?</h1>
        <p>Persoonsgegevens ‘verwerken’ betekent elke bewerking of elk geheel van bewerkingen met betrekking tot persoonsgegevens of een geheel van persoonsgegevens, al dan niet uitgevoerd via geautomatiseerde procedures, zoals het verzamelen, vastleggen, ordenen, structureren, opslaan, bijwerken of wijzigen, opvragen, raadplegen, gebruiken, verstrekken door middel van doorzending, verspreiden of op andere wijze ter beschikking stellen, aligneren of combineren, afschermen, wissen of vernietigen van gegevens.</p>
        <p>Deze sectie legt uit welke persoonsgegevens we verwerken, waar we ze bekomen en waarom.</p>
        <p>Indien u wenst deel te nemen aan het Evenement – dat digitaal op een platform wordt georganiseerd en toegankelijk is via het domein <router-link to="/">{{url}}</router-link> (hierna ‘het Platform’) – moet u zich op voorhand registreren op <router-link to="/">{{url}}</router-link>. Deze voorafgaande registratie vereist op haar beurt dat bepaalde persoonsgegevens met ons worden gedeeld. Deze persoonsgegevens zijn onder meer:</p>
        <ul>
            <li>Identiteitsgevens zoals uw voornaam, meisjesnaam, achternaam, gebruikersnaam of gelijkaardige identificatie, profielfoto, titel, beroepsfunctie en geslacht. Dit omvat ook andere audiovisuele inhoud waarin u verschijnt door bij te dragen of deel te nemen aan het Evenement.</li>
            <li>Contactgegevens zoals uw e-mailadres, bedrijfsadres, land.</li>
            <li>Technische gegevens zoals uw logingegevens, browsertype en -versie, hardware-informatie, tijdzone-instelling en locatie, browser plug-in soorten en versies, besturingssysteem en website alsook andere technologie op de toestellen die u gebruikt om toegang te krijgen tot het Platform.</li>
            <li>profielgegevens zoals uw gebruikersnaam en paswoord, instellingen, feedback en antwoorden op enquêtes.</li>
            <li>Gebruiksgegevens zoals informatie over hoe u de diensten op het Platform gebruikt.</li>
            <li>We verzamelen, gebruiken en delen ook geaggregeerde gegevens zoals statistische of demografische gegevens voor alle doeleinden. Geaggregeerde gegevens kunnen worden afgeleid van uw persoonsgegevens. Ze worden echter niet als persoonsgegevens beschouwd aangezien deze gegevens uw identiteit niet rechtstreeks of onrechtstreeks kunnen onthullen.</li>
        </ul>
        <p>Naast deze gegevens die u actief met ons deelt wanneer u het registratieformulier invult, kunnen we ook gegevens met betrekking tot het IP-adres dat u gebruikt, verwerken om de werking van de diensten gerelateerd aan het Evenement te verbeteren.</p>
        <p>Wanneer de registratie is voltooid, ontvangt de geregistreerde deelnemer een e-mail die zijn deelname bevestigt alsook de mogelijkheid om zijn paswoord opnieuw aan te vragen of te veranderen. De toegang tot het Platform wordt enkel ontgrendeld wanneer het Evenement plaatsvindt.</p>

        <h1>WAT ZIJN DE DOELEINDEN VAN DE VERWERKING WAARVOOR DE PERSOONSGEGEVENS ZIJN BESTEMD EN WAT IS DE RECHTSGRONDSLAG HIERVOOR?</h1>
        <p>In de eerste plaats verzamelen we persoonsgegevens op basis van uw geïnformeerde toestemming.</p>
        <p>We verzamelen ook, voor zover nodig, persoonsgegevens die de gebruiker in staat stellen deel te nemen aan de activiteiten die plaatsvinden op het Platform en dus om de overeenkomst die we met u afsluiten met betrekking tot de deelname aan het Evenement, uit te voeren.</p>
        <p>Tot slot verzamelen we uw persoonsgegevens met het oog op het gerechtvaardigde belang dat door ons als de verantwoordelijke voor de verwerking wordt nagestreefd, met name om uw feedback te krijgen over onze diensten en voorstellen.</p>

        <h1>DE BESCHERMING VAN UW PERSOONSGEGEVENS</h1>
        <p>Wij verkopen of verhandelen uw persoonsgegevens niet aan derde partijen. We onthullen enkel persoonsgegevens aan geautoriseerde serviceproviders (zie hieronder).</p>
        <p>Het aantal personen dat toegang heeft tot de persoonsgegevens van de gebruiker, is beperkt. Toegang wordt enkel aan deze personen verleend voor zover dat de betrokken informatie nodig is voor de goede uitvoering van hun taken. Deze geautoriseerde personen zijn in ieder geval gebonden aan een geheimhoudingsplicht.</p>
        <p>We hebben redelijke maatregelen getroffen om uw persoonsgegevens te beschermen tegen vernietiging, het verlies, de wijziging, de ongeoorloofde verstrekking van of de ongeoorloofde toegang tot doorgezonden, opgeslagen of anderszins verwerkte gegevens overeenkomstig de bepalingen van Verordening (EU) 2016/679 (“AVG”).</p>

        <h1>HET DELEN VAN UW GEGEVENS</h1>
        <p>Hierbij informeren wij u dat het Evenement wordt beheerd door <b>Medialife BV</b>, met maatschappelijke zetel te Walter Thijsstraat 2, 3500 Hasselt, die – na een schriftelijke toestemming van ons – ook verantwoordelijk is voor de verwerking en het beheer van de persoonsgegevens die daartoe worden verzameld.</p>
        <p>We hebben Medialife BV ook de toestemming gegeven om specifieke subverwerkers te gebruiken die voldoende garanties bieden om de passende technische en organisatorische maatregelen op zo’n wijze toe te passen dat de verwerking voldoet aan de voorschriften van de AVG-verordening en de bescherming van de rechten van de betrokkene waarborgt.</p>
        
        <h1>HOE LANG VERWERKEN WE UW PERSOONSGEGEVENS?</h1>
        <p>We verwerken de persoonsgegevens van de gebruiker voor de duur van het Evenement. Na het Evenement worden uw persoonsgegevens onmiddellijk en permanent verwijderd.</p>

        <h1>WAT ZIJN UW PRIVACYRECHTEN?</h1>
        <p>Deze sectie beschrijft de rechten die u hebt met betrekking tot uw persoonsgegevens. Indien u uw rechten wil uitoefenen of meer informatie over hen wenst te bekomen, kan u contact met ons opnemen via de contactgegevens die u vindt aan het einde van deze privacyverklaring.</p>

        <h1>RECHT TOT TOEGANG</h1>
        <p>U heeft recht op een bevestiging of uw persoonsgegevens al dan niet worden verwerkt en, indien dit het geval is, verzoeken om inzage van uw persoonsgegevens alsook om informatie met betrekking tot onze verwerking. Indien u vragen hebt of meer wenst te weten over de persoonsgegevens die we verwerken, kan u altijd contact met ons opnemen en zullen we u nadere informatie verstrekken.</p>

        <h1>RECHT OP RECTIFICATIE</h1>
        <p>Indien u gelooft dat we onjuiste persoonsgegevens opslaan, zoals verkeerd gespelde namen, een oude naam of een oud adres, kan u ons vragen om dit te verbeteren.</p>

        <h1>RECHT OP GEGEVENSWISSING / RECHT OP VERGETELHEID</h1>
        <p>U heeft het recht ons te verzoeken om permanent uw persoonsgegevens te wissen uit onze bestanden.</p>

        <h1>RECHT OM TOESTEMMING IN TE TREKKEN</h1>
        <p>In het geval dat de verwerking van uw persoonsgegevens gebaseerd is op uw toestemming, heeft u het recht om uw toestemming te allen tijde in te trekken, zonder dat dit gevolgen heeft voor de rechtmatigheid van de verwerking die reeds is gebeurd op basis van de toestemming gegeven voor deze intrekking. Merk op dat alle aspecten van een gestarte marketingcampagne niet kunnen worden beëindigd na de intrekking van een toestemming.</p>

        <h1>RECHT OM EEN KLACHT IN TE DIENEN BIJ EEN TOEZICHTHOUDENDE AUTORITEIT</h1>
        <p>Indien u niet tevreden bent met de manier waarop we uw persoonsgegevens verwerken, heeft u het recht een klacht tegen ons in te dienen bij de bevoegde toezichthoudende autoriteit; voor België: de Gegevensbeschermingsautoriteit. U vindt haar contactgegevens hieronder.</p>

        <h1>WIE CONTACTEREN?</h1>
        <p>Indien u vragen heeft over onze privacyverklaring of -praktijken, kan u contact met ons opnemen. U kan <b>To The Point Events</b> contacteren als de algemene beheerder van het Evenement: <a href="mailto:info@tothepointevents.be">info@tothepointevents.be.</a></p>
        <p>Indien u een klacht heeft over de manier waarop uw persoonsgegevens worden verwerkt, kan u de Gegevensbeschermingsautoriteit contacteren via:</p>

        <p>Drukpersstraat 35, 1000 Brussels</p>
        <p>E-mail: <a href="mailto:contact@apd-gba.be">contact(at)apd-gba.be</a></p>
        <p>Telefoonnummer: +32 (0)2 274 48 00</p>
     </div>
  </b-tab>
</b-tabs>
</div>
</template>
<script>
import Header from '@/components/Header'
// import Footer from '@/components/Footer'

export default {
  components: {Header},
  data() {
      return {
        url: window.location.hostname,
        compliance_items: [
          { Naam: 'complianz_policy_id', retentie: '1 jaar', Functie: 'Registreert de geaccepteerde cookiebeleid-ID.' },
          { Naam: 'complianz_content_status', retentie: '1 jaar', Functie: 'Slaat cookievoorkeur op.' },
          { Naam: 'complianz_cmplz_marketing', retentie: '1 jaar', Functie: 'Slaat cookievoorkeur op.' },
        ],
        wpml_items: [
          { Naam: 'wp-wpml_current_language', Retentie: '1 dag', Functie: 'Slaat taalvoorkeuren op.'}
        ],
        googleAnal_items: [
          { Naam: '_ga', Retentie: '2 jaar', Functie: 'Volgt en verzamelt analyse van paginaweergaven voor websitestatistieken.'},
          { Naam: '_gat', Retentie: '1 minuut', Functie: 'Filtert verzoeken van bots.'},
          { Naam: '_gid', Retentie: '1 dag', Functie: 'Volgt en verzamelt analyse van paginaweergaven voor websitestatistieken.'},
        ],
        googleFont_items: [
          { Naam: 'Google Fonts API', Retentie: 'geen', Functie: 'Vraagt het IP-adres van de gebruiker op.'}
        ],
        vimeo_items: [
          { Naam: '__utmt_player', Retentie: '10 minuten', Functie: 'Houdt het bereik van de doelgroep bij.'},
          { Naam: 'vuid', Retentie: '2 jaar', Functie: 'Slaat de gebruiksgeschiedenis van de gebruiker op.'}
        ],
        rumble_items: [
          { Naam: 'AWSALBCORS', Retentie: '1 week', Functie: 'Amazon’s cookie voor CORS-toegang. Deze is nodig om CORS toegang te krijgen in Amazon.'},
          { Naam: 's', Retentie: 'sessie', Functie: 'Een sessiecookie die wordt gebruikt om de gebruiker te verbinden met zijn huidige sessie. Het wordt verwijderd wanneer het tabblad wordt gesloten en aangemaakt wanneer het tabblad wordt geopend.'},
          { Naam: 'r', Retentie: '2 weken', Functie: 'Deze cookie wordt geplaatst wanneer de gebruiker inlogt en wordt gebruikt om de gebruiker ingelogd'}
        ],
        slido_items: [
          { Naam: '__exponea_(*), xnpe_(*), __EX(*)', Retentie: '3 jaar', Functie: 'Deze cookies worden gebruikt om informatie te verzamelen over hoe bezoekers functies en onze website gebruiken. We gebruiken de informatie om rapporten samen te stellen en onze website te verbeteren. De cookies verzamelen informatie op een manier die niemand direct identificeert totdat ze een Slido-account aanmaken.'},
          { Naam: '_fbp fr, act, oo, presence, spin, wd, xs, sb, sfau, wd', Retentie: '3 maanden', Functie: 'Gebruikt door Facebook om een reeks advertentieproducten te leveren, zoals realtime bieden van externe adverteerders.'},
          { Naam: 'Slido.Privacy', Retentie: '10 jaar', Functie: 'Slaat de cookiestatus van de gebruiker op voor het huidige domein.'},
          { Naam: 'slido_(*), Slido.(*), currentAccountUuid, AWSALB, _passenger_route', Retentie: '10 jaar', Functie: 'Technisch, vereist voor onze infrastructuur (cookie bevat een token om de inlogsessie te verifiëren enz.).'},
          { Naam: 'JSESSIONID, TSNGUID, optimizelyEndUserId, _gcl_au, _mkto_trk, s_(*), _persistenceTest', Retentie: 'sessie', Functie: 'We gebruiken de New Relic-service om de uptime/downtime-status van onze websites te controleren.'},

        ],
        zoom_items: [
          { Naam: '_zm_lang', Retentie: '1 jaar', Functie: 'Slaat taalvoorkeuren op'},
        ]
      }
    },
  computed: {
    client() {
      return this.$store.state.Config.settings.client.name;
    },
  },
};
</script>

<style lang="scss" scoped>
html,
.cookie-doc, .privacy-doc {
  padding: 50px;
  background: #fff;
}

a {
  color: blue;
  :hover {
    color: black;
  }
}
.elipsis-list {
  ul, li {
    list-style-type: circle !important;
  }
 
}
</style>