<template>
    <div>
  <!-- Background Image -->
    <div
      v-if="this.settings.features.backgroundImage"
      class="opacity-background"
      :style="{
        backgroundImage: 'url(' + this.settings.client.background + ')',
      }"
    ></div>
  <Header/>
    <b-container class="admin-container">
        <b-card>
            <!-- Branding options -->
            <b-form-group
                label-cols-lg="3"
                label="Branding"
                label-size="lg"
                label-class="font-weight-bold pt-0"
                class="mb-0"
            >   
                <b-form-group label="Logo" label-for="form-image" label-cols-lg="2">
                    <b-input-group>
                        <b-input-group-prepend is-text>
                            <b-icon icon="image-fill"></b-icon>
                        </b-input-group-prepend>
                        <b-form-file id="form-image" :disabled="busy" accept="image/*"></b-form-file>
                    </b-input-group>
                </b-form-group>
                 <b-form-group label="Background image" label-for="form-image" label-cols-lg="2">
                     <b-form-checkbox v-model="checked" name="check-button" switch>
                        Background Image <b>{{ checked }}</b>
                    </b-form-checkbox>
                    <b-input-group>
                        <b-input-group-prepend is-text>
                            <b-icon icon="image-fill"></b-icon>
                        </b-input-group-prepend>
                        <b-form-file id="form-image" :disabled="busy" accept="image/*"></b-form-file>
                    </b-input-group>
                </b-form-group>
                <div v-for="color in colors" :key="color.name">
                    <b-form-group
                    id="fieldset-1"
                    :description="`Choose the ${color.name} color for your theme.`"
                    :label="`${color.name} color`"
                    label-for="input-1"
                    label-cols-sm="3"
                    label-align-sm="right"
                    class="admin-label"
                    >
                        <b-form-input id="input-1" v-model="$data[color.name + '_color']" type="color" trim></b-form-input>
                    </b-form-group>
                </div>
            </b-form-group>
        </b-card>

        <!-- Features options -->
        <b-card>
            <b-form-group
                label-cols-lg="3"
                label="Features"
                label-size="lg"
                label-class="font-weight-bold pt-0"
                class="mb-0"
            >
                <b-form-group label="Login Required" label-cols-lg="2">
                     <b-form-checkbox v-model="loginRequired" name="check-button" switch>
                        <b>{{ loginRequired }}</b>
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group label="Basic authentication" label-cols-lg="2">
                     <b-form-checkbox v-model="authentication" name="check-button" switch>
                        <b>{{ authentication }}</b>
                    </b-form-checkbox>
                </b-form-group>
            </b-form-group>
        </b-card>

        <!-- Livestream options -->
        <b-card>
            <b-form-group
                label-cols-lg="3"
                label="Livestream"
                label-size="lg"
                label-class="font-weight-bold pt-0"
                class="mb-0"
            >
                <b-form-group
                    label="Livestream id"
                    label-cols-sm="3"
                    label-align-sm="right"
                >
                    <b-form-input></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Language"
                    label-cols-sm="3"
                    label-align-sm="right">
                    <b-form-select v-model="selectedLangLivestream" :options="livestreamLanguageOptions"></b-form-select>
                </b-form-group>
                <b-form-group
                    label="Title"
                    label-cols-sm="3"
                    label-align-sm="right"
                >
                    <b-form-input></b-form-input>
                </b-form-group>
                    <b-form-group
                    label="Description"
                    label-cols-sm="3"
                    label-align-sm="right"
                >
                    <b-form-input></b-form-input>
                </b-form-group>
            </b-form-group>
        </b-card>

        <!-- Chat options -->
        <b-card class="last-card">
            <b-form-group
                label-cols-lg="3"
                label="Chat"
                label-size="lg"
                label-class="font-weight-bold pt-0"
                class="mb-0"
            >

                <b-form-group
                    label="Chat id"
                    label-cols-sm="3"
                    label-align-sm="right"
                >
                    <b-form-input></b-form-input>
                </b-form-group>
            </b-form-group>
        </b-card>
    </b-container>
    </div>
</template>
<script>
export default {
    data() {
        return {
            colors: [
                { name: 'primary' },
                { name: 'secondary' },
                { name: 'success' },
                { name: 'warning' },
                { name: 'error' },
            ],
            primary_color: this.$store.state.Config.settings.styling.primary,
            secondary_color: this.$store.state.Config.settings.styling.secondary,
            success_color: this.$store.state.Config.settings.styling.success,
            warning_color: this.$store.state.Config.settings.styling.warning,
            error_color: this.$store.state.Config.settings.styling.error,
            checked: true,
            loginRequired: false, 
            authentication: this.$store.state.Config.settings.features.authentication,
            livestreamType: this.$store.state.Config.settings.livestream.type,
            livestreamTitle: this.$store.state.Config.settings.livestream.title,
            selectedLangLivestream: this.$store.state.Config.settings.livestream.lang,
            livestreamLanguageOptions: [
                "en",
                "nl"
            ]

        }
    },
    computed: {
    settings() {
      return this.$store.state.Config.settings;
    },
    }
}
</script>
<style lang="scss">
    .last-card {
        margin-bottom: 50px !important;
    }
</style>