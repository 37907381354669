import axios from 'axios'

const Config = {
  namespaced: true,
  state: {
    settings: {
      client: {
        name: '',
        logo: "",
        logo2: "",
        notification: "",
        profilepicture: "",
        admin: "",
        background: "",
        favicon: ""
      },
      features: {
        coming_soon: false,
        loginRequired: false,
        authentication: "",
        backgroundImage: false
      },
      styling: {
        primary: '',
        secondary: '',
        text_color_header: '',
        text_color_title: '',
        text_color_body: '',
        warning: '',
        success: '',
        danger: '',
        fontfamily: ''
      },
      languages: [
        '',
        ''
      ],
      livestream: {
        id: '',
        api_key: null,
        api_secret: null,
        leaveUrl: '',
        password: '',
        type: '',
        lang: ''
      },
      interactive_livestream: {
        type: '',
        url: '',
        roomName: '',
        iframeSource: '',
      },
      chat: {
        id: '',
        type: ''
      },
      attachments: []
    }
  },
  getters: {
    allSettings: (state: { settings: any }) => state.settings
  },
  mutations: {
    SET_SETTINGS(state: { settings: any }, settings: any) {
      state.settings = settings
    }
  },
  actions: {
    async getSettings({ commit }) {
      const response = await axios.get(process.env.VUE_APP_API_URL + '/settings.php', {
        headers: { "Access-Control-Allow-Origin": "*" },
        responseType: 'json',
      })
      commit('SET_SETTINGS', response.data)
    }
  }
} as any

export default Config