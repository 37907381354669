<template>
<div>
  <Header/>
  <b-container class="login-card-container">
    <b-card class="auth-card">
      <form name="form" @submit.prevent="handleRegister">
        <div v-if="!successful">
          <div class="form-group">
            <label for="Email">{{ $t('email') }}</label>
            <input
              v-model="user.email"
              v-validate="'required|min:3|max:50'"
              type="text"
              class="form-control"
              name="Email"
            />
            <div
              v-if="submitted && errors.has('Email')"
              class="alert-danger"
            >
              {{ errors.first("Email") }}
            </div>
          </div>
          <div class="form-group">
            <label for="firstname">{{ $t('firstname') }}</label>
            <input
              v-model="user.firstname"
              v-validate="'required|max:50'"
              type="text"
              class="form-control"
              name="firstname"
            />
            <div v-if="submitted && errors.has('firstname')" class="alert-danger">
              {{ errors.first("firstname") }}
            </div>
          </div>
          <div class="form-group">
            <label for="lastname">{{ $t('lastname') }}</label>
            <input
              v-model="user.lastname"
              v-validate="'required|max:40'"
              type="text"
              class="form-control"
              name="lastname"
            />
            <div
              v-if="submitted && errors.has('lastname')"
              class="alert-danger"
            >
              {{ errors.first("lastname") }}
            </div>
          </div>
          <div class="form-group">
            <b-button type="submit" variant="primary" class="color-secondary text-color-title" block>{{ $t('signup') }}</b-button>
          </div>
        </div>
      </form>

      <div
        v-if="message"
        class="alert"
        :class="successful ? 'alert-success' : 'alert-danger'"
      >
        {{ message }}
      </div>
    </b-card>
  </b-container>
  <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: "CodeAuthentication",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      user: { email: "", firstname: "", lastname: ""},
      submitted: false, 
      successful: false, 
      message: "",
    }
  },
  watch: {
    // Checks if user is logged in with basic Auth, push to home when true
    isLoggedIn: function() {
      if(this.isLoggedIn == true){
        this.$router.push("/")
      }
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['Auth/isLoggedIn']
    },
    settings() {
      return this.$store.state.Config.settings;
    },
  },
  mounted() {
    if (this.isLoggedIn == true) {
      this.$router.push("/")
    }
  },
  methods: {
    handleRegister() {
      this.message = "";
      this.submitted = true;

      this.$validator.validate().then((isValid) => {
        if (isValid) {
          this.$store.dispatch('Auth/register', this.user).then(
            (data) => {
              this.message = data.message;
              this.successful = true;
            },
            (error) => {
              this.message = error;
              this.successful = false;
            }
          );
        }
      });
    }
  }
}
</script>
<style lang="scss">
    .login-card-container {
      min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
      min-height: 75vh; /* These two lines are counted as one :-)       */

      display: flex;
      align-items: center;

    }
</style>