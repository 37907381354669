<template>
  <iframe
    :src="`https://vimeo.com/event/${settings.livestream.id}`"
    id="vimeo-iframe"
    frameborder="0"
    allow="autoplay; fullscreen;"
    allowfullscreen
    data-vimeo-tracked="true"
    :class="{'full-height': !chatActive}"
  ></iframe>
</template>
<script>
export default {
  props: ["settings", "chatActive"],
  name: "Vimeo"
};
</script>
<style lang="scss"></style>
