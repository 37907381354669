import Vue from 'vue'
import Vuex from 'vuex'
import Auth from "./modules/auth.module"
import Config from "./modules/config.module"
import Popup from "./modules/popup.module"

Vue.use(Vuex)

const store = new Vuex.Store<any>({
  modules: {
    Config,
    Auth,
    Popup
  },
})


export default store