<template>
<div>
  <!-- <b-toast id="message-toast" title="User message" static no-auto-hide> 
    {{ this.message.text }}
    <a>{{ this.message.url }}</a>
  </b-toast> -->
</div>
</template>
<script>
  import axios from 'axios'
  export default {
    name: "Popup",
    data() {
      return {
        message: {
          "email": "",
          "text": "",
          "url": ""
        }
      }
    },
    watch: {
      message: function() {
        if (this.message.text !== undefined && this.isLoggedIn) {
          this.makeToast()
        }
      }
    },
    computed: { 
      settings() {
        return this.$store.state.Config.settings
      },
      isLoggedIn() {
        if (this.$auth0.isAuthenticated || this.$store.getters["Auth/isLoggedIn"]) {
          return true
        } else {
          return false
        }
      },
      authenticationType() {
        return this.settings.features.authentication
      },
      currentUserEmail() {
        let email
        if (this.isLoggedIn) {
          console.log("logged in")
          if (this.authenticationType == "basic") {
            email =  this.$store.state.Auth.user.email
          } else if (this.authenticationType == "auth0") {
            email = this.$auth0.user.email
          }
        } else {
          console.log("not logged in")
          email = ""
        }

        return email
      }
    },
    methods: {
      makeToast() {
        console.log("toost")
        this.$root.$bvToast.toast(`${this.message.text}`, {
          title: "New message from admin!",
          href: this.message.url,
          appendToast: true,
          variant: 'info',
          autoHideDelay: 20000,
          toaster: 'b-toaster-top-center',
        })
      },
      getMessageByEmail() {
      const isEqual = (...objects) => objects.every(obj => JSON.stringify(obj) === JSON.stringify(objects[0]));
       axios.get(process.env.VUE_APP_API_URL + '/message.php?email=' + this.currentUserEmail)
       .then((response) => {
          if (!isEqual(this.message, response.data)) {
            this.message = response.data
          }
        })
      }
    },
    mounted() { 
      if (this.isLoggedIn) {
        this.getMessageByEmail()
        
        setInterval(function () {
          this.getMessageByEmail()
          console.log(this.message)
        }.bind(this), 30000)
      }
    }
  }
</script>
<style lang="scss">
  .toast {
    color: black;
    a {
      color: black;
    }
  }
</style>