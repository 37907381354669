<template>
  <iframe 
    :src="`https://vimeo.com/event/${settings.chat.id}`"
    width="100%" 
    height="100%" 
    class="chat"
    frameborder="0">
  </iframe>
</template>
<script>
export default {
  name: "vimeo",
  props: ["currentUser", "settings"],
};
</script>
<style lang="scss">
.chat {
  height: 75%;
  min-height: 536.364px;
  min-width: 265.455px;
}
</style>