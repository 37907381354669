<template>
  <div>
    <Header />
    <div class="coming-soon-container">
      <div class="coming-soon">
        <div class="blob-container">
          <div class="blob">
              <!-- <div class="wrapper">
                <svg class="hourglass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 206" preserveAspectRatio="none">
                    <path class="middle" d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"/>
                    <path class="outer" d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"/>
                </svg>
              </div> -->
              <div class="coming-soon-blob-content">
                  <!-- <div v-if="this.settings.client.name == 'Tribespot'" class="blob-tribespot"></div> -->
                  <!-- <img class="coming-soon-logo" :src="this.settings.client.logo"/> -->
                <div class="coming-soon-text">
                  <h1 class="text-color-header">{{ $t("comingsoon") }}</h1>
                </div>
              </div>
          </div>
          <!-- <div class="circle"></div> -->
          
          <!-- <h1 class="coming-soon-text text-color-header">{{ $t("comingsoon") }}</h1> -->
          <!-- <h1 class="text-color-header">{{ $t("comingsoon") }}</h1> -->
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "ComingSoon",
  components: {
    Header,
    Footer,
  },
  computed: {
    settings() {
      return this.$store.state.Config.settings;
    },
  },
};
</script>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);
.coming-soon-container {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 75vh; /* These two lines are counted as one :-)       */
  padding: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  z-index: 999;
}
.img-container {
  display: inline-block;
  position: relative;
}
.coming-soon-logo {
  max-height: 150px;
  max-width: auto;
}
.coming-soon {
  width: 100%;
  font-family: $font-family;
  text-align: center;
  margin: 0 auto;
  vertical-align: middle;
  color: $text-color-header;
  font-size: 40px;
  text-transform: uppercase;
  font-weight: bold;
  z-index: 999;
  letter-spacing: 15px;
  // text-shadow: -3px 0 1px rgb(30, 242, 241), 3px 0 1px rgb(246, 5, 10);
}
// .coming-soon-text { 
//   position: relative;
// }
.coming-soon-blob-content {
  position: relative;
  top: 33%;
  height: 100%;
  margin: 0 auto;
}
.blob {
  background: $color-primary;
  position: absolute;
  border-radius: 50%;
  margin: 10px;
  height: 13em;
  width: 13em;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 25%;
  z-index: -1;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  // animation: pulse 3s infinite;
}
.blob-tribespot {
  background: #e2810a;
  position: absolute;
  border-radius: 50%;
  margin: 10px;
  height: 18px;
  width: 18px;
  margin-left: auto;
  margin-right: auto;
  left: 76.5%;
  right: 0;
  top: 11.8%;
  z-index: 999;
  box-shadow: 0 0 0 0 #e2810a;
  transform: scale(1);
  // animation: pulse-tribespot 2s infinite;
}
// .circle {
//   width: 30px;
//   height: 30px;
//   margin: 10px;
//   background: $color-secondary;
//   border-radius: 50%;
//   position: absolute;
//   top: 43%;
//   left: 0;
//   right: 0;
//   z-index: -1;
//   overflow: hidden;
//   margin-left: auto;
//   margin-right: auto;
//   animation: circle 18s linear infinite;
// }
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
@keyframes pulse-tribespot {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(226, 192, 10, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(226, 192, 10, 0);
  }
}
@keyframes circle {
  0% {
    transform: rotate(0deg) translate(-230px) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translate(-230px) rotate(-360deg);
  }
}
// Hourglass
.hourglass{
    display:block;
    background:$color-primary;
    position: absolute;
    // margin:3em auto;
    width:4em;
    height:8em;
    top: 20%;
    left: 0;
    right: 0;
    z-index: -1;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    opacity: 0.1;
    animation: hourglass 7s linear infinite;
}
.outer{
    fill:$color-secondary;
}
.middle{
    fill:$color-primary;
}
@keyframes hourglass{
    0%{
        transform:rotate(0deg);
        box-shadow:
            inset $color-secondary 0 -0em 0 0,
            inset $color-primary 0 -4em 0 0,
            inset $color-secondary 0 -8em 0 0;
    }
    80%{
        transform:rotate(0deg);
        box-shadow:
            inset $color-secondary 0 -4em 0 0,
            inset $color-primary 0 -4em 0 0,
            inset $color-secondary 0 -4em 0 0;
    }
    100%{
        transform:rotate(180deg);
        box-shadow:
            inset $color-secondary 0 -4em 0 0,
            inset $color-primary 0 -4em 0 0,
            inset $color-secondary 0 -4em 0 0;
    }
}
@media only screen and (max-width: 675px) {
  .blob {
    top: 30% !important;
  }
  .coming-soon-logo {
    width: 100%;
    margin-bottom: 10px;
  }
  .blob-tribespot {
    top: 17%;
  }
}
@media only screen and (max-width: 750px) {
  .coming-soon {
    width: 100%;
    font-size: 25px;
  }
}
</style>