<template>
<div>
  <Header/>
  <b-container>
    <router-link class="nav-link" to="/">
      <font-awesome-icon
        class="label-icon"
        icon="arrow-left"
      ></font-awesome-icon>{{ $t("goback") }}
    </router-link>
    <b-card>
      <div class="row">
        <div class="profile-side col-3">
          <div class="profile-header">
            <div class="profile-picture">
              <img :src="$auth0.user.picture" />
            </div>
            <div class="profile-info">
              <h2>{{ $auth0.user.name }}</h2>
              <p class="text-muted">{{ $auth0.user.email }}</p>
            </div>
          </div>
          <!-- <ul>
          <li>
            <a>
              <span class="account-icon">
                <font-awesome-icon class="label-icon" icon="user"></font-awesome-icon>
              </span>
              <span class="account-title">{{ $t('account') }}</span>
              <span class="account-arrow">
                <i class="faicon-angle-right"></i>
              </span>
            </a>
          </li> 
       </ul>   -->
        </div>

        <!-- Edit Profile -->
        <div class="col-8 profile-main">
          <div class="account-heading">
            <font-awesome-icon
              class="label-icon"
              icon="user"
            ></font-awesome-icon>
            {{ $t("account") }}
          </div>
          <!-- Username field -->
          <div class="account-field">
            <div class="field-label">
              <label for="username">{{ $t("username") }}</label>
            </div>
            <div class="field-area">
              <input :value="$auth0.user.name" />
            </div>
          </div>
          <!-- Firstname field -->
          <div class="account-field">
            <div class="field-label">
              <label for="username">{{ $t("firstname") }}</label>
            </div>
            <div class="field-area">
              <input :value="$auth0.user.given_name" />
            </div>
          </div>
          <!-- Lastname field -->
          <div class="account-field">
            <div class="field-label">
              <label for="username">{{ $t("lastname") }}</label>
            </div>
            <div class="field-area">
              <input :value="$auth0.user.family_name" />
            </div>
          </div>
          <!-- Email field -->
          <div class="account-field">
            <div class="field-label">
              <label for="username">{{ $t("email") }}</label>
            </div>
            <div class="field-area">
              <input :value="$auth0.user.email" />
            </div>
          </div>
          <!-- Submit button -->
          <div class="account-submit">
            <input
              type="submit"
              name="account_submit"
              class="profile-button"
              :value="$t('updateaccount')"
            />
          </div>
        </div>
      </div>
    </b-card>
  </b-container>
</div>
</template>
<script>
export default {
  computed: {
    settings() {
      return this.$store.state.Config.settings;
    },
  },
};
</script>
<style lang="scss">
.label-icon {
  margin-right: 10px;
}
.profile-container {
  /* grid-area: content; */
  width: 100%;
  padding-top: 35px;
  padding-left: 60px;
  padding-right: 60px;
}
.profile-side {
  .profile-header {
    text-align: center;
    .profile-picture {
      img {
        max-height: 120px;
        border-radius: 999px;
      }
    }
    .profile-info {
      text-align: center;
      padding-top: 12px;
    }
  }

  ul {
    margin: 0 !important;
    padding: 0 !important;
    list-style-type: none !important;
    list-style: disc;
  }
  li {
    margin-bottom: 1px !important;
    background: #eee;

    a {
      text-decoration: none;
      font-size: 16px !important;
      height: 30px;
      line-height: 20px;
      color: #000000 !important;
      position: relative;
      .account-icon {
      }
      .account-title {
        padding-left: 20px;
        float: left;
        height: 30px;
        line-height: 30px;
      }
      .account-arrow {
      }
    }
  }
}

.profile-main {
  float: left;
  width: 70%;
  padding: 0px 0px 0px 1.5em;
  box-sizing: border-box;

  .account-heading {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    margin: 0 !important;
    font-size: 24px;
    line-height: 28px;
    font-weight: bold;
  }

  .account-field {
    position: relative;
    padding: 15px 0 0 0;

    .field-label {
      display: flex;
      flex-direction: row;
      align-items: baseline;
      flex-wrap: nowrap;
      justify-content: flex-start;
      margin: 0 0 8px 0;

      label {
        font-size: 16px;
        line-height: 22px;
        font-weight: 700;
      }
    }
    .field-area {
      position: relative;
      word-wrap: break-word;

      input {
        color: #000000;
        border: 1px solid #ddd !important;
        padding: 0 12px !important;
        width: 100%;
        display: block !important;
        height: 40px !important;
        box-sizing: border-box !important;
        box-shadow: none !important;
        margin: 0 !important;
        position: static;
        outline: none !important;
      }
    }
  }
  .account-submit {
    margin: 10px 0px 5px;
    padding-top: 10px;
    font-size: 15px;

    .profile-button {
      font-size: 15px;
      padding: 15px;
      background: none;
      border: 2px solid #fff !important;
    }
  }
}
</style>