import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "@/views/Home.vue";
import BasicProfile from "@/views/BasicProfile.vue";
import Auth0Profile from "@/views/Auth0Profile.vue";
import Admin from "@/views/Admin.vue";
import Register from "@/views/Register.vue";
import ComingSoon from "@/views/ComingSoon.vue";
import MobileChat from "@/views/MobileChat.vue"
import store from "@/store/index";
import CodeAuthentication from "@/views/CodeAuthentication.vue";
import ZoomPlayer from "@/components/video/ZoomPlayer.vue";
import CookiePrivacyDoc from "@/components/CookiePrivacyDoc.vue";

import { authGuard } from "../auth0/authGuard";
import { loadLanguageAsync } from "../plugins/i18n";

Vue.use(VueRouter);

let routes: Array<RouteConfig> = [];

routes = [
  {
    path: "/",
    alias: ["/home"],
    name: "Home",
    meta: {
      authRequired: true,
    },
    component: Home,
  },
  {
    path: "/profile-b",
    name: "profileBasic",
    meta: {
      authRequired: true,
    },
    component: BasicProfile,
  },
  {
    path: "/profile-a",
    name: "profileAuth0",
    meta: {
      authRequired: true,
    },
    component: Auth0Profile,
  },
  {
    path: "/admin",
    name: "admin",
    meta: {
      authRequired: true,
    },
    component: Admin,
  },
  {
    path: "/authentication",
    name: "authentication",
    component: Register,
  },
  {
    path: "/login",
    name: "login",
    component: CodeAuthentication,
  },
  {
    path: "/zoom",
    name: "zoom",
    component: ZoomPlayer,
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/coming-soon",
    name: "comingSoon",
    component: ComingSoon,
  },
  {
    path: "/mobile",
    name: "mobileChat",
    component: MobileChat,
  },
  {
    path: "/cookie-privacy",
    name: "cookie",
    component: CookiePrivacyDoc,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch("Config/getSettings");
  await loadLanguageAsync(store.state.Config.settings.languages);
  // Vue.prototype.$appTitle = store.state.Config.settings.client.name;
  const loginRequired = store.state.Config.settings.features.loginRequired;
  const authentication = store.state.Config.settings.features.authentication;
  const comingSoon = store.state.Config.settings.features.coming_soon;
  

  if (to.path !== "/coming-soon" && comingSoon ) {
    return next("/coming-soon")
  } else if (to.path == "/coming-soon" && !comingSoon) {
    return next("/")
  }
  
  // set public pages when setting loginRequired is switched
  if (!loginRequired || !to.meta?.authRequired) {
    return next();
  }
  // auth0 authentication option
  if (authentication == "auth0") {
    authGuard(to, from, next);
  } else if (authentication == "basic") {
    // Basic authentication
    const loggedIn = localStorage.getItem("user");
    // trying to access a restricted page + not logged in
    // redirect to login page
    if (!loggedIn) {
      return next("/authentication");
    }
  } else if (authentication == "code") {
    const loggedIn = localStorage.getItem("user");
    // trying to access a restricted page + not logged in
    // redirect to login page
    if (!loggedIn) {
      return next("/login");
    }
  }
  
  return next();
});

export default router;
